<template>
    <div class="">
        <router-link class="position-relative promotion-card card border-0 rounded-xl shadow text-dark text-decoration-none overflow-hidden h-100"
                     v-bind:class="{ dragging }"
                     v-bind:to="`/promotions/${ promotionId }`">
            <div class="position-absolute tags-wrapper m-2">
                <h5 class="font-weight-bolder m-0">
                    <span class="badge"
                          v-bind:style="{ 'background-color': categoryColor, 'color': categoryTextColor, }">
                        {{ categoryName }}
                    </span>
                </h5>
            </div>
            <div class="cover"
                 v-bind:style="{ 'background-image': `url(${ apiUrl }/${ apiVersion }/promotion/image/${ promotion.images[0] })` }">
            </div>
            <div class="card-body">
                <h5 class="card-title font-weight-bolder">
                    {{ promotion.heading }}
                </h5>
                <h6 class="card-subtitle mb-2 text-muted"
                    v-if="promotion.subheading">
                    {{ promotion.subheading }}
                </h6>
            </div>
        </router-link>
    </div>
</template>

<script>
import { apiUrl, apiVersion, } from "@/config";
import tinycolor from "tinycolor2";
export default {
    name: "PromotionCard",
    components: {

    },
    props: {
        "dragging": {
            type: Boolean,
            default: false,
        },
        "promotion": {
            type: Object,
        },
    },
    data () {
        return {
            apiUrl, apiVersion,
        };
    },
    computed: {
        promotionId () {
            return this.promotion?._id ?? "";
        },
        category () {
            return this.promotion?.category ?? null;
        },
        categoryName () {
            return this.category?.name ?? "";
        },
        categoryColor () {
            return this.category?.color ?? "";
        },
        categoryTextColor () {
            const color = tinycolor(this.categoryColor);
            return color.isLight() ? "#000000" : "#FFFFFF";
        },
    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>
.promotion-card {
    transition: transform 300ms ease;

    .cover {
        height: 200px;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: #343a40;
    }

    .tags-wrapper {
        top: 0;
        left: 0;
        right: 0;
    }

    .buttons {
        left: 0;
        bottom: 0;
        right: 0;
    }

    &.dragging {
        pointer-events: none;
        animation-name: shake;
        animation-duration: 1000ms;
        animation-iteration-count: infinite;
    }

    &:not(.dragging) {
        &:hover,
        &:focus {
            transform: scale(1.01);
        }
    }

    @keyframes shake {
        from,
        to {
            transform: translate3d(0, 0, 0);
        }

        10%,
        30%,
        50%,
        70%,
        90% {
            transform: translate3d(-2.5px, 0, 0);
        }

        20%,
        40%,
        60%,
        80% {
            transform: translate3d(2.5px, 0, 0);
        }
    }
}
</style>
